import React, {useEffect, useState} from 'react';
import Jumbotron from '../../component/Jumbotron';
import dummyInfo from '../../asset/dummy/_info';
import { truncateHTML, truncateText } from '../../utils/utils';
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInfo } from '../../store/actions/infoAction';
import { Helmet } from 'react-helmet';

const RagamInfo = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const data = useSelector(state => state.info.data);
    const loading = useSelector(state => state.info.loading);
    const [header, setHeader] = useState([])

    const location = useLocation();
    const navigate = useNavigate()
        
    const[list, setList]=useState([])

    const initialFilter = {
        category: null,
        page: 1,
        limit: 15,
        search: '',
        sort: 'desc',
        sortBy: 'created_at'
    }
    const[filter, setFilter]=useState(initialFilter)

    const infoTab = [
        {id:'news', label:'Berita', menuId:96}, 
        {id:'article', label:'Artikel', menuId: 97}, 
        {id:'story', label:'Berbagi Cerita', menuId:98}
    ]

    useEffect(() => {
        const key = infoTab.filter((a) => a.id == id)[0];
        const head = [
            {
                images: 'https://s3-alpha-sig.figma.com/img/6996/8626/66dc2eeb4578941cadb8d0c64969adb4?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bc2vRETEC7CSGA3RKwab6s5Cm9wPlL97hhewcsNdxgVVAu29zN6dVP2wq6IpE67r6wPFuZuJtl0yrJmqNuK7NoiByR0wve2mBnyv-DJreiTz~USscnSZUotmabvYG4NV~HpklR7yAOS13A1LlNZK-ZA7DflKUPqOLc1eBd1Fl-fIlRcdVwS9C57aSkLrmPi0VX4Hi3j9hFupUJ1otTuJ0Zhardhyy48GX6sXMByVByMl5PoURLLkwe9aCszI37EEIaVQW5b3N7mfG6CbWgsq1H7Ewzv-Krp5ZKzChg9P6UkIwVcPgpIlgl9Wza4I7JuVdOv~8lVNZeMhbSAI51LCqg__',
                title: key.label
            }
        ]
        setHeader(head)
    },[id])

    useEffect(() => {
        const key = infoTab.filter((a) => a.id == id)[0];
        setFilter({ ...initialFilter, category:key.id})
    }, [id]);

    useEffect(() => {
        dispatch(getInfo(filter.category, filter.sort, filter.page, filter.search, filter.sortBy));
    },[filter])

    const doSorting = (e) => {
        if(e.target.value.includes('title')){
            setFilter({ ...filter, sort: e.target.value.replace('title_', ''), page: 1, sortBy: 'title' })
        }else{
            setFilter({ ...filter, sort: e.target.value, page: 1, sortBy: 'created_at' })
        }
    }

    // const renderPageNumbers = () => {
    //     const pageNumbers = [];
    //     const totalPages = data?.pagination?.total_pages;

    //     if (totalPages > 5) {
    //         const startPage = Math.min(filter.page, totalPages - 2);
    //         const endPage = Math.min(startPage + 2, totalPages);

    //         for (let i = startPage; i <= endPage; i++) {
    //             pageNumbers.push(i);
    //         }

    //         if (endPage < totalPages) {
    //             pageNumbers.push('...', totalPages);
    //         }
    //     } else {
    //         for (let i = 1; i <= totalPages; i++) {
    //         pageNumbers.push(i);
    //         }
    //     }
    //     return pageNumbers;
    // };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPages = data?.pagination?.total_pages;

        if (totalPages > 5) {
          pageNumbers.push(1);
    
          if (filter.page > 3) {
            pageNumbers.push('...');
          }
    
          const startPage = Math.max(Math.min(filter.page, totalPages - 2), 2);
          const endPage = Math.min(startPage + 2, totalPages - 1);
    
          for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
          }
    
          if (endPage < totalPages) {
            pageNumbers.push('...');
          }
    
          pageNumbers.push(totalPages);
        } else {
          for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
          }
        }
        return pageNumbers;
      };

    const handlePageClick = (page) => {
        if (page !== '...') {
          setFilter({ ...filter, page });
        }
    };

    return (
        <div>
            <Helmet>
                <title>{header[0]?.title + " - Direktorat SMP"}</title>
                <meta name="description" content="" />
                <meta property="og:title" content={header[0]?.title + " - Direktorat SMP"} />
                <meta property="og:description" content="" />
                <meta property="og:image" content="" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className='pt-[70px]'>
                <Jumbotron data={[{images: data?.category?.image?.url, title: data?.category?.description }]}/>

                <div className="md:px-20">
                    <div className="md:grid md:grid-cols-3 md:px-20 px-6 md:py-20 py-6 md:gap-2">
                        <div className="col-span-3 mb-10">
                            <div className="md:grid grid-cols-3 mb-5 md:space-y-0 space-y-2">
                                <input type="text" 
                                    className="col-span-1 col-start-1 border rounded-lg p-2.5 w-full" 
                                    placeholder="Cari..." 
                                    value={filter.search}
                                    onChange={(e) => setFilter({ ...filter, search: e.target.value, page: 1 })}
                                />

                                <div className="col-span-1 col-start-3 flex items-center gap-2">
                                    <div className="text-gray-700 flex-none hidden md:block">Urutkan : </div>
                                    <select 
                                        className="flex-auto border rounded-lg p-2.5" 
                                        onChange={(e) => doSorting(e)}
                                    >
                                        <option value="desc" selected={filter.sort === 'desc' && filter.sortBy === 'created_at'}>Terbaru</option>
                                        <option value="asc" selected={filter.sort === 'asc' && filter.sortBy === 'created_at'}>Terlama</option>
                                        <option value="title_asc" selected={filter.sort === 'asc' && filter.sortBy === 'title'}>A-Z</option>
                                        <option value="title_desc" selected={filter.sort === 'desc' && filter.sortBy === 'title'}>Z-A</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div className="md:grid md:grid-cols-5 gap-6">
                                {data?.data?.map((item,key) => {
                                    return(
                                        <div onClick={() => navigate(`/ragam-informasi/${id}/${item.slug}`)} key={key} className='rounded-lg shadow-lg border border-[#eee] relative w-full items-center py-3 flex flex-col cursor-pointer hover:shadow-sm transition ease-in-out mb-3 md:mb-0 px-3'>
                                            <div className="">
                                                <img src={item?.featured_image?.url} alt="Ragam Informasi" className='aspect-[32/15] rounded-lg object-cover' />
                                            </div>
                                            <div className="py-2 w-full z-20">
                                                <div className="text-gray-800 font-bold mb-2 break-words">{truncateText(item?.title || '', 30)}</div>
                                                <div className="text-xs text-gray-600" dangerouslySetInnerHTML={{  __html:truncateHTML(item?.content, 70, true)}}></div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={`flex items-center justify-between w-full mt-8 py-8 border-t-2 border-[#eee] md:mt-20`}>
                                <div className={`flex-none flex items-center cursor-pointer ${filter.page === 1 ? 'hidden' : 'block'}`} onClick={() => setFilter({ ...filter, page: filter.page-1 })}>
                                    <ArrowLeft size={20} color='#333' />
                                    <span className="font-semibold ml-2">Sebelumnya</span>
                                </div>
                                <div className="flex-auto flex items-center justify-center gap-2">
                                    {renderPageNumbers().map((page, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handlePageClick(page)}
                                            className={`${filter.page === page ? 'bg-[#F0F7FC] text-[#008DD1]' : 'bg-[#FFF] text-gray-800'} font-semibold w-12 h-12 rounded-sm flex items-center justify-center cursor-pointer hover:text-[#008DD1]`}
                                        >
                                            {page}
                                        </div>
                                    ))}
                                </div>
                                <div className={`flex-none flex items-center cursor-pointer ${filter.page === data?.pagination?.total_pages ? 'hidden' : 'block'}`} onClick={() => setFilter({ ...filter, page: filter.page+1 })}>
                                    <span className="font-semibold mr-2">Selanjutnya</span>
                                    <ArrowRight size={20} color='#333' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    };

export default RagamInfo;