import ig1 from '../images/ig/1.jpg'
import ig2 from '../images/ig/2.jpg'
import ig3 from '../images/ig/3.jpg'
import ig4 from '../images/ig/4.jpg'
import ig5 from '../images/ig/5.jpg'
import ig6 from '../images/ig/6.jpg'

const dummyGallery = [
    {link:'https://www.instagram.com/share/p/BAMJcL3ZZk', images: ig1},
    {link:'https://www.instagram.com/share/p/BAPZhRDI6r', images: ig2},
    {link:'https://www.instagram.com/share/p/_sxChnE__', images: ig3},
    {link:'https://www.instagram.com/share/p/BAQhQwLaFU', images: ig4},
    {link:'https://www.instagram.com/share/p/_hovQmmjd', images: ig5},
    {link:'https://www.instagram.com/share/p/BAG1Hwpkas', images: ig6},
]

export default dummyGallery;